






















































































































































































































































































































































































































.set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    box-sizing: border-box;
}
.tab-box {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .right-wrapper {
        margin-top: 20px;
    }
}
::v-deep .input-box {
    margin-right: 20px;
    margin-top: 20px;
    .el-input-group__prepend {
        background: #fff;
    }
    .select-box {
        width: 180px;
    }
    .el-input-group__append {
        color: #fff;
        background: #409eff;
    }
}
